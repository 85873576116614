<template>
  <div class="h-full bg-graynazih flex xl:items-center xl:justify-center">
    <div
      class="
        flex
        bg-white
        rounded-none
        xl:rounded-cu
        shadow
        border-0
        xl:border
        border-solid border-gray-400
        w-full
        xl:w-auto
      "
    >
      <div
        class="
          flex flex-col gap-y-4
          border-0 border-r border-solid border-gray-200
          px-2
          py-2
          w-full
          lg:w-400
          justify-center
        "
      >
        <div class="flex justify-center">
          <img src="/logos/32/logo.png" class="h-auto w-auto" title="dok.ma" />
        </div>

        <ValidationObserver ref="observer" class="flex flex-col gap-y-3">
          <ValidationProvider
            ref="validationInput"
            name="password"
            rules="required"
            id="password"
          >
            <template class="relative" slot-scope="{ errors }">
              <dok-input
                d-placeholder="Entrez un nouveau mot de passe"
                label="Entrez un nouveau mot de passe"
                :custom-class="errors[0] ? 'border-red-500' : ''"
                :d-type="!showpass ? 'password' : 'text'"
                size="md"
                :d-model.sync="data.password"
                v-model="data.password"
              >
                <template v-slot:rightIcon>
                  <svg
                    class="cursor-pointer"
                    @click="showpass = !showpass"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="#111"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                    />
                    <path
                      stroke="#111"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 15c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z"
                    />
                  </svg>
                </template>
              </dok-input>
              <div
                v-if="data.password.length"
                class="h-1 rounded-full my-2"
                :style="
                  `width: ${percentPassword()}%; background: ${percentBackground(
                    percentPassword()
                  )}`
                "
              ></div>
              <div class="flex flex-col" v-if="data.password.length">
                <span
                  class="flex flex-row gap-x-1 items-center font-EffraR"
                  :class="
                    validation.has_length ? 'text-green-500' : 'text-red-500'
                  "
                >
                  <i
                    class="far fa-check-circle"
                    v-if="validation.has_length"
                  ></i
                  ><i
                    class="far fa-times-circle"
                    v-if="!validation.has_length"
                  ></i
                  >Veuillez saisir au moins 8 caractères.
                </span>
                <span
                  class="flex flex-row gap-x-1 items-center font-EffraR"
                  :class="
                    validation.has_number ? 'text-green-500' : 'text-red-500'
                  "
                >
                  <i
                    class="far fa-check-circle"
                    v-if="validation.has_number"
                  ></i
                  ><i
                    class="far fa-times-circle"
                    v-if="!validation.has_number"
                  ></i
                  >Veuillez saisir quelques chiffres
                </span>
                <span
                  class="flex flex-row gap-x-1 items-center font-EffraR"
                  :class="
                    validation.has_lowercase ? 'text-green-500' : 'text-red-500'
                  "
                >
                  <i
                    class="far fa-check-circle"
                    v-if="validation.has_lowercase"
                  ></i
                  ><i
                    class="far fa-times-circle"
                    v-if="!validation.has_lowercase"
                  ></i
                  >Veuillez saisir des lettres minuscules
                </span>
                <span
                  class="flex flex-row gap-x-1 items-center font-EffraR"
                  :class="
                    validation.has_uppercase ? 'text-green-500' : 'text-red-500'
                  "
                >
                  <i
                    class="far fa-check-circle"
                    v-if="validation.has_uppercase"
                  ></i
                  ><i
                    class="far fa-times-circle"
                    v-if="!validation.has_uppercase"
                  ></i
                  >Veuillez saisir des lettres majuscules
                </span>
                <span
                  class="flex flex-row gap-x-1 items-center font-EffraR"
                  :class="
                    validation.has_special ? 'text-green-500' : 'text-red-500'
                  "
                >
                  <i
                    class="far fa-check-circle"
                    v-if="validation.has_special"
                  ></i
                  ><i
                    class="far fa-times-circle"
                    v-if="!validation.has_special"
                  ></i
                  >Veuillez saisir quelques caractères spéciaux
                </span>
              </div>
            </template>
          </ValidationProvider>

          <ValidationProvider
            ref="validationInput"
            name="repassword"
            rules="required|confirmed:password"
            id="repassword"
          >
            <template class="relative" slot-scope="{ errors }">
              <dok-input
                d-placeholder="Vérifier le mot de passe"
                label="Vérifier le mot de passe"
                :custom-class="errors[0] ? 'border-red-500' : ''"
                :d-type="!showpass ? 'password' : 'text'"
                size="md"
                :d-model.sync="data.repassword"
                v-model="data.repassword"
              >
                <template v-slot:rightIcon>
                  <svg
                    class="cursor-pointer"
                    @click="showpass = !showpass"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="#111"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                    />
                    <path
                      stroke="#111"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 15c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z"
                    />
                  </svg>
                </template>
              </dok-input>
            </template>
          </ValidationProvider>

        </ValidationObserver>

        <div class="flex justify-center mt-2">
          <dok-button @click.native="onClickChange" :has-icon="true">
            <i class="fas fa-angle-right text-2xl"></i>
          </dok-button>
        </div>

        <div class="flex justify-center my-2">
          <router-link
            to="/"
            class="text-center text-base font-semibold text-dokBlue-ultra"
            ><i class="fas fa-home"></i> Revenir à la page d'accueil
          </router-link>
        </div>
      </div>

      <div class="hidden xl:flex items-center px-5">
        <img src="/svg/secure.svg" class="max-h-64" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      data: { password: "" },
      validation: {
        has_number: false,
        has_lowercase: false,
        has_uppercase: false,
        has_special: false,
        has_length: false
      },
      showpass: false
    };
  },
  watch: {
    validation: {
      handler(newValue, oldValue) {
        this.percentPassword();
      },
      deep: true
    },
    "data.password"(newVal, oldVal) {
      this.validation.has_number = /\d/.test(this.data.password);
      this.validation.has_lowercase = /[a-z]/.test(this.data.password);
      this.validation.has_uppercase = /[A-Z]/.test(this.data.password);
      this.validation.has_length = this.data.password.length > 7;
      this.validation.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(
        this.data.password
      );
    }
  },
  methods: {
    ...mapActions("user", ["CHECK_RESET_PASSWORD", "CHAGNE_PASSWORD_TOKEN"]),
    percentPassword() {
      let precent = 0;
      if (this.validation.has_number) precent = precent + 20;
      if (this.validation.has_lowercase) precent = precent + 20;
      if (this.validation.has_uppercase) precent = precent + 20;
      if (this.validation.has_special) precent = precent + 20;
      if (this.validation.has_length) precent = precent + 20;

      return precent;
    },
    percentBackground(val) {
      if (val === 0) return "#DDD";
      if (val === 20) return "#EF4444";
      if (val === 40) return "#DB2777";
      if (val === 60) return "#D97706";
      if (val === 80) return "#fbbf24";
      if (val === 100) return "#10B981";
    },
    onDataChangePassword({ ok, message }) {
      if (!ok) {
        this.$vs.loading.close();

        return this.$vs.notify({
          color: "danger",
          text: message
        });
      }
      if (ok) {
        this.$vs.notify({
          text: "Votre mot de passe a été changé avec succès"
        });
      }

      this.$vs.loading.close();
      this.$router.push("/login");
    },
    async onClickChange() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;

      if (this.percentPassword() !== 100) {
        return;
      }

      this.$vs.loading();
      this.CHAGNE_PASSWORD_TOKEN({
        token: this.$route.query.token,
        password: this.data.password,
        onData: this.onDataChangePassword
      });
    }
  },
  created() {
    if (!this.$route.query.token) {
      this.$router.push("/login");
    }
  }
};
</script>
